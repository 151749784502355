/* Base styles */
.home-header {
    position: relative;
    width: 100%;
    height: 100vh; /* Full viewport height */
    overflow: hidden;
    display: flex;
    align-items: center; /* Center the content vertically */
    justify-content: center; /* Center the content horizontally */
}

.home-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(30, 30, 30, 0.7); /* #1E1E1E with 70% opacity */
    z-index: 1; /* Place the overlay behind the content but above the video */
}

.home-background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

/* Hide video controls on iOS */
.home-background-video::-webkit-media-controls {
    display: none !important;
  }
  
  .home-background-video::-webkit-media-controls-play-button {
    display: none !important;
  }
  
  .home-background-video::-webkit-media-controls-start-playback-button {
    display: none !important;
  }

  
.rdts-logo {
    justify-content: flex-start;
}

.rdts-logo img{
    max-width: 100%;
    max-height: 100%;
    width: 100px;
    height: 50px;
}

.home-nav {
    position: absolute;
    top: 3.61vw; /* 52px / 1440px * 100vw */
    padding-right: 4.64vw; /* 66.78px / 1440px * 100vw */
    padding-left: 4.86vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 2; /* Ensure the nav is above the video */
    box-sizing: border-box;
}

.home-nav-links {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2.36vw; /* Converted from 2.125rem */
    margin: 0;
    padding: 0;
    margin-left: auto;
}

.home-nav-links a {
    color: #fff; /* White text color for contrast against the video */
    text-decoration: none;
    font-weight: 500;
    font-size: 1.25rem; /* Adjust to vw if needed */
    line-height: 1.5125rem; /* Adjust to vw if needed */
}

.home-book-now {
    background-color: #017A5D;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    padding: 0.91rem 1.75rem; /* Consider converting to vw or use `em` */
    font-weight: 700;
    font-size: 0.91rem; /* Adjust to vw if needed */
    line-height: 1.10125rem; /* Adjust to vw if needed */
    cursor: pointer;
    text-transform: uppercase;
}

.home-book-now:hover {
    background-color: #015f49;
}

.home-hero-content {
    position: absolute;
    left: 4.86vw; /* 70px / 1440px * 100vw */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Center the content vertically */
    color: #fff; /* Text color */
    max-width: 50%; /* Limit the width of the paragraph */
    padding: 1rem; /* Consider using `em` or `vw` for padding */
    z-index: 1; /* Ensure content is above the video */
}

.home-hero-content p {
    font-size: 1.25rem; /* Adjust to vw if needed */
    line-height: 1.5; /* Adjust to vw if needed */
    margin: 0; /* Remove default margin */
    padding-top: 2.57vw; /* 37px / 1440px * 100vw */
    padding-bottom: 3.96vw; /* 57px / 1440px * 100vw */
}

.hero-heading {
    font-size: 3.33vw; /* 48px */
    line-height: 4.03vw; /* 58.09px */
    margin: 0;
}

.hero-content {
    font-size: 1.11vw; /* 16px */
    line-height: 1.53vw; /* 22px */
    margin: 0;
    padding-top: 2.57vw; /* 37px */
    padding-bottom: 3.96vw; /* 57px */
}
/* Hamburger menu and close icon */
.home-menu-icon {
    display: none; /* Hidden by default */
    font-size: 5vw; /* Size of the hamburger icon */
    cursor: pointer;
    position: absolute;
    right: 1vw; /* Position to the right */
    top: 1vw; /* Position from the top */
    z-index: 3;
    color: #fff;
}

.home-close-icon {
    display: none; /* Hidden by default */
    font-size: 28px; /* Size of the close icon */
    cursor: pointer;
    position: absolute;
    top: 2vw; /* Position from the top */
    right: 4vw; /* Position from the right */
    z-index: 4;
}

/* Sidebar Menu */
.home-nav-links.open {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    background-color: #fff; /* Background color of the sidebar */
    padding: 2vw;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Shadow effect */
    z-index: 3; /* Ensure it’s above other content */
    overflow-y: auto; /* Scroll if content overflows */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
    gap: 25px;
}

.home-nav-links.open.hide {
    opacity: 0;
    transform: translateX(-100%);
}

.home-nav-links.open li {
    gap: 25px; /* Spacing between menu items */
    color: #000;
}

.home-nav-links.open a {
    color: #000;
    font-size: 16px;
}

.home-nav-links.open .book-now {
    width: 30%; /* Full width for the button in sidebar */
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    border-radius: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .home-menu-icon {
        display: block; /* Show hamburger icon */
    }

    .home-nav-links {
        display: none; /* Hide links by default */
    }

    .home-nav-links.open {
        display: flex; /* Show links when open */
    }

    .home-close-icon {
        display: block; /* Show close icon */
    }

    .home-hero-content {
        left: 2vw; /* Adjust for smaller screens */
        right: 2vw; /* Ensure full width on smaller screens */
        max-width: 96vw; /* Ensure content takes full width */
    }

    .home-book-now {
        width: 100%; /* Full width for the button on small screens */
        height: 40px;
        font-size: 14px;
    }

    .hero-heading {
        font-size: 24px; /* 48px */
        line-height: 28px;
    }
    
    .hero-content {
        font-size: 12px;
        line-height: 19px;
    }
}

@media (min-width: 769px) {
    .home-menu-icon {
        display: none; /* Hide hamburger icon on larger screens */
    }

    .home-nav-links {
        display: flex; /* Show links */
    }

    .home-close-icon {
        display: none; /* Hide close icon on larger screens */
    }
}
