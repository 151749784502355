.contact-us-parent {
    position:relative;
    z-index:0;
}

@media (max-width: 768px) {
    .contact-us-parent {
       top: 40px;
       height: 100%;
    }
}