/* src/style.css */

/* Container styling */
.about-us-container {
    background: url('../../Assets/about.png') no-repeat center center;
    background-size: cover;
    /* Ensure the background covers the entire container */
    width: 100%;
    /* Full width of the viewport */
    aspect-ratio: 2.015;
    /* Aspect ratio based on width and height */
    margin-top: 6.063rem;
    /* Space above the container */
    position: relative;
    /* Needed for positioning the content */
    padding: 6.75rem 4.313rem;
    /* Padding for all sides */
    display: flex;
    justify-content: left;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    color: #fff;
}

/* Content styling */
.about-us-content {
    max-width: 751px;
    /* Maximum width for large screens */
    width: 100%;
    /* Full width on smaller screens */
    height: auto;
    border-radius: 1.25rem;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 2rem 3.5rem 3.438rem;
    /* Padding for top, sides, and bottom */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: justify;
    gap: 2.5rem;
}

.learn-more-btn {
    width: 18.3125rem;
    height: 4.4375rem;
    border-radius: 0.938rem;
    font-size: 1.222rem;
    font-weight: 700;
    background: #017A5D;
}

@media (max-width: 768px) {
    .about-us-content {
        font-size: 12px;
    }

    .learn-more-btn {
        font-size: 12px;
    }
}