.service-card {
    background: #D9D9D9;
    border-radius: 0.76rem;
    display: flex;
    flex-direction: column;
    width: 95%; /* This controls the width of the card */
    padding-bottom: 2.5rem;
    box-sizing: border-box;
    height: 100%;
    align-items: center;
}

.service-image {
    width: 100%; /* Make sure the image container takes up the full width of the service card */
}

.service-image img {
    width: 100%;    /* Ensure the image fills the container */
    height: auto;   /* Maintain aspect ratio */
    object-fit: cover; /* Ensures the image doesn't stretch or distort */
}


.service-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.25rem; /* Adjusted padding */
    padding-bottom: 2rem; /* Adjusted padding */
    padding-left: 2.92rem; /* Adjusted padding */
    padding-right: 2.92rem; /* Adjusted padding */
    flex-grow: 1; /* Allows the content to take up available space and push the button to the bottom */
    width: 100%;
}

.service-heading {
    color: var(--primary-color);
    font-size: 1.5rem;
    text-align: center;
    font-weight: 600;
    line-height: 1.85rem; /* Adjusted line-height */
}

.service-desc {
    font-size: 1.2rem; 
    align-items: center;
    text-align: center;
    font-weight: 500;
    line-height: 1.5rem; /* Adjusted line-height */
    color: var(--secondary-color);
    padding-top: 1.5rem; /* Consistent padding */
}

.service-btn-div {
    display: flex;
    justify-content: center; /* Center the button */
    margin-top: auto; /* Pushes the button to the bottom */
    padding-top: 1.5rem;
}

.service-btn {
    width: 100%;
    font-size: 1.2rem;
    font-weight: 700;
    border: 1px solid #017A5D;
    border-radius: 0.8rem;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .service-card {
        padding-bottom: 4vw; /* Adjust padding for smaller screens */
    }

    .service-content {
        padding-top: 2rem; /* Increase padding-top for better spacing */
        padding-bottom: 3rem; /* Increase padding-bottom for better spacing */
        padding-left: 3rem; /* Adjust padding-left for smaller screens */
        padding-right: 3rem; /* Adjust padding-right for smaller screens */
    }

    .service-heading {
        font-size: 1rem; /* Increase font size for headings on smaller screens */
        line-height: 1.5rem; /* Adjust line-height for headings */
    }

    .service-desc {
        font-size: 1rem; /* Increase font size for descriptions on smaller screens */
        line-height: 1.5rem; /* Adjust line-height for descriptions */
        padding-top: 1rem; /* Adjust padding-top for descriptions */
    }

    .service-btn-div {
        padding-top: 1rem;
    }

    .service-btn {
        font-size: 1rem;
    }
}
