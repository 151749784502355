/* src/OurChoices.css */

/* Container for the entire section */
.our-choices-container {
    padding-top: 6.228rem;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
}

/* Container for the carousel */
.carousel-container {
    display: flex;
    gap: 3.125rem; /* Space between items */
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    -ms-overflow-style: none;  /* Hide scrollbar in IE and Edge */
    width: 100%; /* Full width of the parent */
    padding-top: 3.938rem;
    height: 100%;
    overflow-y: hidden;
}

/* Hide scrollbar in Webkit browsers (Chrome, Safari) */
.carousel-container::-webkit-scrollbar {
    display: none;
}

/* Optional: Adjust the width of each item */
.choices-card {
    flex: 0 0 auto; /* Prevent flex items from shrinking */
    height: 100%;
}
