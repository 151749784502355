.service-root {
    padding-top: 3.188rem;
    padding-left: 4.438rem;
    padding-right: 4.125rem;
    padding-bottom: 3.438rem;
}

.service-breadcrumbs {
    font-size: 0.91rem;
    line-height: 1.10rem;
    font-weight: 700;
    display: flex;
    padding-bottom: 3.875rem;
}

.service-breadcrumbs .main{
    color: #000;
}

.service-breadcrumbs .secondary{
    color: var(--primary-color);
}

.service-description {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    font-size: 1.25rem;
    line-height: 2.313rem;
    text-align: justify;
}

.service-parent {
    position:relative;
    z-index:0;
}

@media (max-width: 768px) {
    .service-parent {
       top: 40px;
       height: 100%;
    }
}