.our-servicescontainer {
    padding-top: 5.188rem;
    padding-left: 4.438rem;
    padding-right: 4.438rem;
}

.service-parent {
    position:relative;
    z-index:0;
}

@media (max-width: 768px) {
    .service-parent {
       top: 40px;
       height: 100%;
    }
}