.footer {
    background-color: #000;
    width: 100%;
    box-sizing: border-box;
    padding-top: 6.9375rem;
    padding-left: 70px;
    padding-right: 11px;
    color: #fff;
}

.footer-row {
    width: 100%;
}

.footer-col {
    display: flex;
    align-items: center;
}

.footer-map {
    width: 46.31rem;
    height: 19.56rem;
    max-width: 100%;
    max-height: 100%;
}

.footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.9rem;
}

.footer-links li:nth-child(1) {
    font-size: 1.5rem;
    line-height: 1.9rem;
}


.footer-logo {
    max-width: 100%;
    height: auto;
}

.social-icons {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    padding-top: 1rem;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
}

.copyright {
    margin: 0;
    padding: 6px;
    font-size: 0.875rem;
}

.map-col {
    justify-content: flex-end;
}


@media (max-width: 768px) {
    .footer {
        padding: 20px;
    }

    .social-icons {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 15px;
    }

    .map-col {
        padding-top: 15px;
        justify-content: center;
    }
}