.header {
    position: relative;
    padding: 3.47vw 4.85vw; /* Converted from 3.125rem 4.36125rem */
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}

.nav {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}
.rdts-logo {
    justify-content: flex-start;
}

.rdts-logo img{
    max-width: 100%;
    max-height: 100%;
    width: 150px;
    height: 50px;
}
.menu-icon {
    display: none; /* Hidden by default */
    font-size: 5vw; /* Size of the hamburger icon */
    cursor: pointer;
    position: absolute;
    right: 1vw; /* Position to the right */
    top: 1vw; /* Position from the top */
}

.close-icon {
    display: none; /* Hidden by default */
    font-size: 28px; /* Size of the close icon */
    cursor: pointer;
    position: absolute;
    top: 2vw; /* Position from the top */
    right: 4vw; /* Position from the right */
}

.nav-links {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 2.36vw; /* Converted from 2.125rem */
    margin: 0;
    padding: 0;
    margin-left: auto;
}

.nav-links a {
    color: #000;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.39vw; /* Converted from 1.25rem */
    line-height: 1.68vw; /* Converted from 1.5125rem */
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: #017A5D;
}

.book-now {
    background-color: #017A5D;
    color: #fff;
    border: none;
    border-radius: 0.35vw; /* Converted from 0.25rem */
    padding: 1.01vw 1.94vw; /* Converted from 0.91rem 1.75rem */
    width: 9.86vw; /* Converted from 8.88875rem */
    height: 2.39vw; /* Converted from 2.15375rem */
    font-weight: 700;
    font-size: 1.01vw; /* Converted from 0.91rem */
    line-height: 1.22vw; /* Converted from 1.10125rem */
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: background-color 0.3s ease;
}

.book-now:hover {
    background-color: #015f49;
}

/* Sidebar Menu and Hamburger Icon */
.nav-links.open {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    background-color: #fff; /* Background color of the sidebar */
    padding: 2vw;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Shadow effect */
    z-index: 3; /* Ensure it’s above other content */
    overflow-y: auto; /* Scroll if content overflows */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.nav-links.open.hide {
    opacity: 0;
    transform: translateX(-100%);
}

.nav-links.open li {
    margin: 2vw 0; /* Spacing between menu items */
}

.nav-links.open .book-now {
    width: 30%; /* Full width for the button in sidebar */
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .menu-icon {
        display: block; /* Show hamburger icon */
    }

    .nav-links {
        display: none; /* Hide links by default */
    }

    .nav-links.open {
        display: flex; /* Show links when open */
    }

    .nav-links.open a
    {
        font-weight: 500;
        font-size: 24px; /* Converted from 1.25rem */
        line-height: 1.68vw; 
    }

    .close-icon {
        display: block; /* Show close icon */
    }
}

@media (min-width: 769px) {
    .menu-icon {
        display: none; /* Hide hamburger icon on larger screens */
    }

    .nav-links {
        display: flex; /* Show links *//* Converted from 1.5125rem */
    }

    .nav-links.open a
    {
        font-weight: 500;
        font-size: 24px; /* Converted from 1.25rem */
        line-height: 1.68vw; 
    }

    .close-icon {
        display: none; /* Hide close icon on larger screens */
    }
}
