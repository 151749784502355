
/* Font Smoothing */
body,html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif;
  font-size: 16px; /* Base font size relative to the root font size */
  line-height: 1.5;
  color: var(--secondary-color);
  background-color: #ffffff; /* Optional: Set a global background color */
}

/* Base Font Size Setup for Responsiveness */
:root {
  font-size: 16px; /* Responsive root font size based on 1440px design */
  /* Color Variables */
  --primary-color: #017A5D;
  --success-color: #017A5D;
  --secondary-color: #000000;
  --danger: #DD5471;
}

/* Code Font Styling */
code {
  font-family: "Inter", sans-serif;
}

:root {
  font-size: 16px; /* Base font size */
}

h1 {
  font-size: 32px; /* Example font size for h1 */
}

p {
  font-size: 16px; /* Example font size for paragraphs */
}

html {
  font-size: 16px; /* Base font size */
}

h1 {
  font-size: 32px; /* Example font size for h1 */
}

p {
  font-size: 16px; /* Example font size for paragraphs */
}


@media (min-width: 1920px) {
  :root {
    font-size: 21.3px; /* 16px * (1920/1440) */
  }

  body {
    padding: 26.7px; /* 20px * (1920/1440) */
  }

  h1 {
    font-size: 42.7px; /* 32px * (1920/1440) */
  }

  p {
    font-size: 21.3px; /* 16px * (1920/1440) */
  }

}

@media (max-width: 1023px) and (min-width: 768px) {
  :root {
    font-size: 8.5px; /* 16px * (768/1440) */
  }

  h1 {
    font-size: 17px; /* 32px * (768/1440) */
  }

  p {
    font-size: 8.5px; /* 16px * (768/1440) */
  }

}

@media (max-width: 767px) {
  :root {
    font-size: 6.8px; /* 16px * (640/1440) */
  }

  h1 {
    font-size: 13.6px; /* 32px * (640/1440) */
  }

  p {
    font-size: 6.8px; /* 16px * (640/1440) */
  }

}





