.choices-card {
    width: 26.25rem;
    height: 21.188rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1.125rem;
}

.choices-image{
    border-radius: 0.625rem;
    /* max-width: 100%;
    max-height: 100%; */
    width: 100%;
    max-width:31.9375rem;
    height: 18.938rem;
}

.choices-image img{
    border-radius: 0.625rem;
    max-width: 100%;
    max-height: 100%;
    width:31.9375rem;
    height: 18.938rem;
}

.choice-name {
    font-size: 1.528rem;
    font-weight: 600;
    line-height: 1.848rem;
    color: var(--primary-color);
}

@media (max-width: 768px) {
    .choice-name {
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        color: var(--primary-color);
    }
}