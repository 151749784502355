.about-parent {
    position:relative;
    z-index:0;
}

.about-us-banner {
    height: 35.938vw; /* Convert height to viewport width for responsiveness */
    background: url('../../Assets/about-us-background.png');
    background-size: cover;
    background-position: center; /* Ensure background image is centered */
}

.about-container {
    padding-left: 4.375vw; /* Convert padding to viewport width */
    padding-right: 4.375vw; /* Convert padding to viewport width */
    padding-top: 5.125vw; /* Convert padding to viewport width */
    padding-bottom: 2.998vw; /* Convert padding to viewport width */
    box-sizing: border-box; /* Ensure padding is included in width calculation */
    height: auto;
}

@media (max-width: 768px) {
    .about-parent {
       top: 40px;
    }
}