.heading-white {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3rem;
    font-weight: 400;
    line-height: 3.631rem;
    color: #fff;
}

.heading-black {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3rem;
    font-weight: 400;
    line-height: 3.631rem;
    color: var(--secondary-color);
}

.second {
    font-weight: 700;
}

.heading-divider {
    background-color: var(--primary-color);
    width: 5.5rem;
    height: 0.375rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}