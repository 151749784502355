  /* Main container with padding */
.main-container {
  padding-left: 4.79rem; /* 69px / 1440px * 16px = 4.79rem */
  padding-right: 4.79rem;
  padding-top: 5.125rem;
  padding-bottom: 7rem;
  height: 100%;
}

/* Main row */
.main-row {
  height: 100%; /* Adjust height to auto */
  width: 100%;
  justify-content: space-between;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
  display: flex;
  flex-direction: row;
  gap: 20px; /* Gap between columns */
}

/* Left partition with a width of 751px */
.left-partition {
  background-color: #000; /* Full black background */
  border-radius: 20px;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  text-align: left;
  align-items: flex-start;
  flex: 1; /* Flexible width for responsiveness */
  text-align: justify;
}

/* Right partition containing two child divs */
.right-partition {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1; /* Flexible width for responsiveness */
  height: 100%;
}

/* Top child div with background color and padding */
.top-child {
  background-color: #017A5D;
  border-radius: 20px;
  padding: 2.813rem;
  margin-bottom: 60px; /* Gap between top and bottom divs */
  height: 50%;
  text-align: justify;
}

/* Bottom child div with background color and padding */
.bottom-child {
  background-color: #017A5D;
  border-radius: 20px;
  padding: 2.813rem;
  height: 50%;
  text-align: justify;
}

.right-partition p {
  font-size: 1rem;
  text-align: justify;
}

.right-partition h6 {
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.723rem;
  color: #fff;
  padding: 0;
  margin: 0;
  padding-bottom: 10px;
}

@media (min-width: 1441px)  {
  .left-partition p, .right-partition p{
    font-size: 20px;
}
}

/* Responsive Styles for screens between 1000px and 1440px */
@media (min-width: 1000px) and (max-width: 1440px) {
  .main-row {
      gap: 20px; /* Gap between columns */
  }

  .left-partition, .right-partition {
      width: calc(50% - 10px); /* Adjust width to fit in the row with a gap */
  }

  .top-child, .bottom-child {
      height: auto; /* Auto height for flexibility */
      margin-bottom: 60px; /* Gap between top and bottom divs */
  }
}

/* Responsive Styles for screens 1005px and smaller */
@media (max-width: 1005px) {
  .main-row {
      flex-direction: column; /* Stack columns vertically */
      gap: 20px; /* Gap between stacked columns */
  }

  .left-partition, .right-partition {
      width: 100%; /* Full width for stacked columns */
      font-size: 14px;
  }

  .top-child, .bottom-child {
      height: auto; /* Auto height for flexibility */
      margin-bottom: 40px; /* Gap between top and bottom divs */
  }

  .left-partition p {
    font-size: 12px;
    line-height: 19px;
  }

  .right-partition p {
    font-size: 12px;
    line-height: 19px;
  }
  
  .right-partition h6 {
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 2.723rem;
  }
  
}
