/* src/Banner.css */

.banner {
    width: 100%; /* Full width */
    height: 31.9375rem; /* Converted height */
    background-image: url('../../Assets/service-banner.png'); /* Replace with your image path */
    background-size: cover; /* Ensure the image covers the div */
    background-position: center;
    position: relative;
}

.banner-overlay {
    width: 100%;
    height: 100%;
    background:  #000000;
    display: flex;
    align-items: flex-end; /* Align text at the bottom */
    padding-left: 4.1875rem; /* Converted padding left */
    padding-bottom: 2rem; /* Optional: Add some padding at the bottom */
    opacity: 0.75;
}

.banner-text {
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 6rem; /* Converted font size */
    font-weight: 700;
    line-height: 6.25rem; /* Converted line height */
    text-align: left;
}

.first-word {
    display: block;
}

.rest-words {
    display: block;
    margin-top: -0.5rem; /* Optional: Adjust to fine-tune line spacing */
}
