.contact-us-root {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
    padding-bottom: 8.688rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-form {
    box-shadow: 0px 127.11px 180.3px -72.12px rgba(25, 58, 75, 0.3);
    border-radius: 2.817rem;
    height: 51.387rem;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.column {
    position: relative;
    width: 100%;
    height: 51.39rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.background {
    position: absolute;
    top: 0;
    right: 0;
    width: 23.61rem;
    height: 51.39rem;
    background-color: #6DE1C2;
    z-index: 1;
    border-top-right-radius: 2.817rem;
    border-bottom-right-radius: 2.817rem;
}

.image {
    position: relative;
    right: 5.18rem;
    z-index: 2;
}

.contact-form-col {
    padding-top: 3.437rem;
    padding-left: 5.522rem;
    padding-right: 3.775rem;
    padding-bottom: 2.139rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.contact-form-col h5 {
    font-size: 2.592rem;
    font-weight: 600;
    line-height: 1.353rem;
    padding-bottom: 1.273rem;
}

.second {
    color: var(--primary-color);
}

.contact-form-col h6 {
    font-size: 0.789rem;
    font-weight: 400;
    line-height: 1.353rem;
    padding-bottom: 1.307rem;
}

.contact-input {
    border: none;
    border-bottom: 1px solid #737B7D;
    border-radius: 0;
    box-shadow: none;
    padding: 0.5rem 0;
    font-size: 0.9rem;
    color: #333;
    height: 3.099rem;
}

.contact-input::placeholder {
    color: #8E8E8E;
    font-weight: 400;
    font-size: 0.901rem;
    line-height: 1.44rem;
}

.submit-button {
    width: 100%;
    background: #017A5D;
    height: 3.053rem;
    font-size: 0.789rem;
    font-weight: 700;
    margin-top: 2.37rem;
    margin-bottom: 2rem;
    border-radius:  0.564rem;
}

.contact-links {
    margin-top: auto;
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.contact {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.926rem;
}

.contact-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 0.733rem;
    font-weight: 600;
    line-height: 1.127rem;
}

.contact-desc span:nth-child(2) {
    color: var(--danger);
}

/* Responsive Styles */
@media (max-width: 1490px) {
    .contact-form {
        flex-direction: column;
    }

    .column {
        display: none;
    }
}

@media (max-width: 768px) {
    .contact-us-root {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 2rem;
    }

    .contact-form {
        height: auto;
        width: 90%;
        padding: 20px;
    }

    .contact-form-col {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .contact-input {
        font-size: 0.8rem;
    }

    .submit-button {
        height: 2.5rem;
        font-size: 0.7rem;
    }
}
