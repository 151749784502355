/* src/OurChoices.css */

/* Container for the entire section */
.our-choices-container {
    padding-top: 6.92vw; /* Converted from 6.228rem */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
}

/* Container for the carousel */
.carousel-container {
    display: flex;
    gap: 3.47vw; /* Converted from 3.125rem */
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    -ms-overflow-style: none;  /* Hide scrollbar in IE and Edge */
    width: 100%; /* Full width of the parent */
    padding-top: 4.38vw; /* Converted from 3.938rem */
}

/* Hide scrollbar in Webkit browsers (Chrome, Safari) */
.carousel-container::-webkit-scrollbar {
    display: none;
}

/* Optional: Adjust the width of each item */
.choices-card {
    flex: 0 0 auto; /* Prevent flex items from shrinking */
}
